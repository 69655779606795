define("ember-ani-mate/animate.css/tada", ["exports", "ember-ani-mate/animate.css/defaults"], function (_exports, _defaults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tada = void 0;
  const tada = _exports.tada = {
    keyframes: [{
      offset: 0,
      transform: 'scale3d(1, 1, 1)'
    }, {
      offset: 0.1,
      transform: 'scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg)'
    }, {
      offset: 0.2,
      transform: 'scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg)'
    }, {
      offset: 0.3,
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)'
    }, {
      offset: 0.4,
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)'
    }, {
      offset: 0.5,
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)'
    }, {
      offset: 0.6,
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)'
    }, {
      offset: 0.7,
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)'
    }, {
      offset: 0.8,
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)'
    }, {
      offset: 0.9,
      transform: 'scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)'
    }, {
      offset: 1,
      transform: 'scale3d(1, 1, 1)'
    }],
    options: {
      duration: _defaults.defaults.duration
    }
  };
});