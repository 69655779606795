define("ember-ani-mate/modifiers/ani-mate", ["exports", "ember-modifier", "ember-ani-mate", "@ember/destroyable", "@ember/object"], function (_exports, _emberModifier, _emberAniMate, _destroyable, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let AniMateModifier = _exports.default = (_class = class AniMateModifier extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      _defineProperty(this, "clone", void 0);
      _defineProperty(this, "parentElement", void 0);
      _defineProperty(this, "nextElementSibling", void 0);
      _defineProperty(this, "scrollTop", void 0);
      _defineProperty(this, "isFirstRun", true);
      (0, _destroyable.registerDestructor)(this, this.destructor);
    }
    /**
     * @type {(HTMLElement|undefined)}
     * @private
     * @readonly
     */
    get el() {
      return this.clone || this._el;
    }
    set el(el) {
      this._el = el;
    }
    modify(element, positional, named) {
      this.parentElement = element.parentElement;
      this.nextElementSibling = element.nextElementSibling;
      this.positional = positional;
      this.named = named;
      this.el = element;
      if (this.isFirstRun) {
        if (this.named.scrollSyncSelector) {
          this.el.querySelector(this.named.scrollSyncSelector)?.addEventListener('scroll', e => this.scrollTop = e.currentTarget?.scrollTop);
        }
        this.transitionIn();
        this.isFirstRun = false;
      }
    }
    destructor() {
      this.transitionOut();
    }

    /**
     * Adds a clone to the parentElement so it can be transitioned out
     *
     * @private
     * @method addClone
     */
    addClone() {
      const original = this._el;
      const parentElement = original.parentElement || this.parentElement;
      let nextElementSibling = original.nextElementSibling || this.nextElementSibling;
      if (nextElementSibling && nextElementSibling.parentElement !== parentElement) {
        nextElementSibling = null;
      }
      if (parentElement?.isConnected) {
        const clone = original.cloneNode(true);
        parentElement.insertBefore(clone, nextElementSibling);
        if (this.scrollTop && this.named.scrollSyncSelector) {
          clone.querySelector(this.named.scrollSyncSelector).scrollTop = this.scrollTop;
        }
        this.clone = clone;
      }
    }

    /**
     * Removes the clone from the parentElement
     *
     * @private
     * @method removeClone
     */
    removeClone() {
      if (this.clone?.isConnected && this.clone.parentNode !== null) {
        this.clone.parentNode.removeChild(this.clone);
      }
    }
    async transitionIn() {
      const {
        skipIn,
        ...options
      } = this.named;
      if (!skipIn) {
        if (isCallbackFn(this.named.in)) {
          await this.named.in(this.el);
        } else if (this.named.in) {
          const keyframe = this.named.in;
          await _emberAniMate.aniMate.from(this.el, keyframe, options);
        }
      }
    }
    async transitionOut() {
      if (this.named.out && !this.named.skipOut) {
        // We can't stop ember from removing the element
        // so we clone the element to animate it out
        this.addClone();
        if (isCallbackFn(this.named.out)) {
          await this.named.out(this.el);
        } else {
          const {
            easing,
            ...keyframe
          } = this.named.out;
          const {
            duration
          } = this.named;
          await _emberAniMate.aniMate.to(this.el, keyframe, {
            duration,
            easing
          });
        }
        this.removeClone();
        this.clone = null;
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "destructor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "destructor"), _class.prototype), _class);
  const isCallbackFn = a => typeof a === 'function';
});