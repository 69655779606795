define("ember-ani-mate/components/smooth-if", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <SmoothIf::Glimmer
    @predicate={{@predicate}}
    @mode={{@mode}}
    @devJumpCheck={{@devJumpCheck}}
    @awaitIn={{@awaitIn}}
    @debug={{@debug}}
    @onRegister={{@onRegister}}
    @hasElseBlock={{has-block "else"}}
    @delayWhenClipping={{@delayWhenClipping}}
    class={{@class}}
    ...attributes
    as |smoothIf|
  >
    {{#if smoothIf.predicate}}
      {{yield smoothIf}}
    {{else}}
      {{yield smoothIf to="else"}}
    {{/if}}
  </SmoothIf::Glimmer>
  */
  {
    "id": "cUdpA8+m",
    "block": "[[[8,[39,0],[[16,0,[30,1]],[17,2]],[[\"@predicate\",\"@mode\",\"@devJumpCheck\",\"@awaitIn\",\"@debug\",\"@onRegister\",\"@hasElseBlock\",\"@delayWhenClipping\"],[[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[48,[30,11]],[30,9]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,10,[\"predicate\"]],[[[1,\"    \"],[18,12,[[30,10]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,11,[[30,10]]],[1,\"\\n\"]],[]]]],[10]]]]]],[\"@class\",\"&attrs\",\"@predicate\",\"@mode\",\"@devJumpCheck\",\"@awaitIn\",\"@debug\",\"@onRegister\",\"@delayWhenClipping\",\"smoothIf\",\"&else\",\"&default\"],false,[\"smooth-if/glimmer\",\"has-block\",\"if\",\"yield\"]]",
    "moduleName": "ember-ani-mate/components/smooth-if.hbs",
    "isStrictMode": false
  });
  class SmoothIf extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "tagName", '');
    }
  }
  _defineProperty(SmoothIf, "positionalParams", ['predicate']);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SmoothIf);
});