define("ember-ani-mate/index", ["exports", "@ember/string", "@ember/debug", "@ember/runloop", "@ember/utils", "teamtailor/utils/type-utils", "ember-ani-mate/animate.css/headShake", "ember-ani-mate/animate.css/bounce", "ember-ani-mate/animate.css/tada", "ember-ani-mate/animate.css/shakeY", "deepmerge", "ember-ani-mate/AniMateArray"], function (_exports, _string, _debug, _runloop, _utils, _typeUtils, _headShake, _bounce, _tada, _shakeY, _deepmerge, _AniMateArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "AniMateArray", {
    enumerable: true,
    get: function () {
      return _AniMateArray.AniMateArray;
    }
  });
  _exports.afterRender = afterRender;
  _exports.processKeyframes = _exports.normalizeElementsArray = _exports.easings = _exports.beforeAfter = _exports.aniMate = void 0;
  _exports.raf = raf;
  _exports.setStyles = setStyles;
  _exports.wait = void 0;
  /* eslint-disable ember/use-ember-get-and-set */

  const keyframePresets = {
    headShake: _headShake.headShake,
    bounce: _bounce.bounce,
    bounceNoScale: _bounce.bounceNoScale,
    shakeY: _shakeY.shakeY,
    tada: _tada.tada
  };
  const keyframePresetNames = (0, _typeUtils.keys)(keyframePresets);
  const overflowKeys = ['overflowY', 'overflowX'];

  // setproperty requires hyphen-case and el.style[key] requires camelCase
  function setPropertyOrRegular(el, key, val) {
    if (key.includes('-')) {
      el.style.setProperty(key, val);
    } else {
      el.style[key] = val;
    }
  }
  function setStyles(elementsArg, style) {
    let elements;
    if (typeof elementsArg === 'string' && elementsArg.startsWith('::view-transition')) {
      const styleEl = document.createElement('style');
      const styleString = Object.entries(pxifyIfNeeded(style)).reduce((acc, [key, val]) => `${acc} ${(0, _string.dasherize)(key)}: ${val};`, '');
      styleEl.appendChild(document.createTextNode(`${elementsArg} {${styleString}}`));
      document.body.appendChild(styleEl);
      elements = styleEl;
    } else {
      elements = normalizeElementsArray(elementsArg);
      elements.forEach(el => {
        for (const [key, val] of Object.entries(pxifyIfNeeded(style))) {
          setPropertyOrRegular(el, key, val);
        }
      });
    }
    return async ({
      await: awaitArray
    } = {}) => {
      if (awaitArray) {
        awaitArray = Array.isArray(awaitArray) ? awaitArray : [awaitArray];
        await Promise.all(awaitArray);
      }
      if (elements instanceof HTMLStyleElement) {
        elements.remove();
      } else {
        elements.forEach(el => {
          Object.entries(style).forEach(([key]) => {
            setPropertyOrRegular(el, key, '');
          });
        });
      }
    };
  }
  const easings = _exports.easings = {
    // from https://www.npmjs.com/package/css-ease
    // Also, some of these are used in animate.css. For example ease-out-cubic here: https://github.com/rauchg/thereallybigone/blob/master/source-sass/_animate.scss#L69

    snap: 'cubic-bezier(0,1,.5,1)',
    linear: 'cubic-bezier(0.250, 0.250, 0.750, 0.750)',
    'ease-in-quad': 'cubic-bezier(0.550, 0.085, 0.680, 0.530)',
    'ease-in-cubic': 'cubic-bezier(0.550, 0.055, 0.675, 0.190)',
    'ease-in-quart': 'cubic-bezier(0.895, 0.030, 0.685, 0.220)',
    'ease-in-quint': 'cubic-bezier(0.755, 0.050, 0.855, 0.060)',
    'ease-in-sine': 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
    'ease-in-expo': 'cubic-bezier(0.950, 0.050, 0.795, 0.035)',
    'ease-in-circ': 'cubic-bezier(0.600, 0.040, 0.980, 0.335)',
    'ease-in-back': 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',
    'ease-out-quad': 'cubic-bezier(0.250, 0.460, 0.450, 0.940)',
    'ease-out-cubic': 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
    'ease-out-quart': 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
    'ease-out-quint': 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
    'ease-out-sine': 'cubic-bezier(0.390, 0.575, 0.565, 1.000)',
    'ease-out-expo': 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
    'ease-out-circ': 'cubic-bezier(0.075, 0.820, 0.165, 1.000)',
    'ease-out-back': 'cubic-bezier(0.175, 0.885, 0.320, 1.275)',
    'ease-in-out-quad': 'cubic-bezier(0.455, 0.030, 0.515, 0.955)',
    'ease-in-out-cubic': 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
    'ease-in-out-quart': 'cubic-bezier(0.770, 0.000, 0.175, 1.000)',
    'ease-in-out-quint': 'cubic-bezier(0.860, 0.000, 0.070, 1.000)',
    'ease-in-out-sine': 'cubic-bezier(0.445, 0.050, 0.550, 0.950)',
    'ease-in-out-expo': 'cubic-bezier(1.000, 0.000, 0.000, 1.000)',
    'ease-in-out-circ': 'cubic-bezier(0.785, 0.135, 0.150, 0.860)',
    'ease-in-out-back': 'cubic-bezier(0.680, -0.550, 0.265, 1.550)'
  };
  const dontPxIfy = ['opacity', 'lineHeight', 'zIndex', 'offset'];
  const pxifyIfNeededKeyVal = (key, value) => typeof value === 'number' && !dontPxIfy.includes(key) ? `${value}px` : !(0, _utils.isNone)(value) ? `${value}` : '';
  function pxifyIfNeeded(keyOrstyle, value) {
    if (typeof keyOrstyle === 'object') {
      const out = {};
      for (const [key, value] of Object.entries(keyOrstyle)) {
        out[key] = pxifyIfNeededKeyVal(key, value);
      }
      return out;
    } else {
      return pxifyIfNeededKeyVal(keyOrstyle, value);
    }
  }
  const processKeyframes = ({
    element,
    keyframesArg,
    options
  }) => {
    const willChange = new Set();
    let setsHeight, setsWidth;
    let keyframes = [keyframesArg[0]];
    keyframes = keyframesArg.map(keyframeArg => {
      const keyframe = keyframeArg instanceof DOMRect ? keyframeArg.toJSON() : {
        ...keyframeArg
      };
      const parsedKeyframeWidth = 'width' in keyframe ? parseInt(keyframe.width) : undefined;
      setsWidth = parsedKeyframeWidth !== undefined && !Number.isNaN(parsedKeyframeWidth);
      const setsWidth0 = setsWidth && parsedKeyframeWidth === 0;
      if (setsWidth) {
        const currentInitial = element.dataset.aniMateInitial ? JSON.parse(element.dataset.aniMateInitial) : {};
        if (options.auto.overflowXClip === 'setsWidth' && !('overflowX' in currentInitial) && element !== document.documentElement) {
          currentInitial.overflowX = element.style.overflowX;
          element.dataset.aniMateInitial = JSON.stringify(currentInitial);
          element.style.overflowX = 'clip';
        }
      }
      if (setsWidth0) {
        if (options.auto.marginInline === 'setsWidth0' && !('marginInline' in keyframe)) {
          const marginInlineKFVal = parseInt(keyframe.marginInline) || 0;
          keyframe.marginInline = 0 + marginInlineKFVal;
        }
      }
      const parsedKeyframeHeight = 'height' in keyframe ? parseInt(keyframe.height) : undefined;
      setsHeight = parsedKeyframeHeight !== undefined && !Number.isNaN(parsedKeyframeHeight);
      const setsHeight0 = setsHeight && parsedKeyframeHeight === 0;
      if (setsHeight) {
        const currentInitial = element.dataset.aniMateInitial ? JSON.parse(element.dataset.aniMateInitial) : {};
        if (options.auto.overflowYClip === 'setsHeight' && !('overflowY' in currentInitial) && element !== document.documentElement) {
          currentInitial.overflowY = element.style.overflowY;
          element.dataset.aniMateInitial = JSON.stringify(currentInitial);
          element.style.overflowY = 'clip';
        }
        if (options.auto.paddingBlock === 'setsHeight' && !('paddingBlock' in keyframe)) {
          keyframe.paddingBlock = 0;
        }
      }
      if (setsHeight0) {
        if (options.auto.marginBlock === 'setsHeight0' && !('marginBlock' in keyframe)) {
          const marginBlockKFVal = parseInt(keyframe.marginBlock) || 0;
          keyframe.marginBlock = 0 + marginBlockKFVal;
        }
        const gapAutoProp = 'marginTop';
        const reverseGapAutoProp = 'marginBottom';
        if (options.auto.parentGap === 'setsHeight0') {
          let gapMatch = element.parentElement?.getAttribute('class')?.match(/( |^)gap-\[?-?(\d+)(px)?\]?( |$)/)?.[2];
          if (!gapMatch) {
            gapMatch = getComputedStyle(element.parentElement).gap;
          }
          if (gapMatch) {
            const gap = parseInt(gapMatch);
            const gapKFVal = parseInt(keyframe[gapAutoProp]) || 0;
            const reverseGapKFVal = parseInt(keyframe[reverseGapAutoProp]) || 0;
            const newVal = (gapKFVal || 0) - gap;
            keyframe[gapAutoProp] = newVal;
            keyframe[reverseGapAutoProp] = 0 + reverseGapKFVal;
          }
        }
      }
      return Object.entries(keyframe).reduce((acc, [key, value]) => {
        willChange.add(key);
        acc[key] = pxifyIfNeeded(key, value);
        return acc;
      }, {});
    });
    return {
      keyframes,
      willChange
    };
  };
  _exports.processKeyframes = processKeyframes;
  const rectKeys = ['height', 'width'];
  const elemsDatas = new WeakMap();
  const isHTMLElementCollection = input => input instanceof HTMLCollection && Array.from(input).every(el => el instanceof HTMLElement);
  const normalizeElementsArray = elements => {
    return Array.isArray(elements) ? elements.compact() : isHTMLElementCollection(elements) ? Array.from(elements) : typeof elements === 'string' ? Array.from(document.querySelectorAll(elements)) : [elements].compact();
  };
  _exports.normalizeElementsArray = normalizeElementsArray;
  const isInteger = a => Number.isInteger(a);
  const propMap = {
    height: 'offsetHeight',
    width: 'offsetWidth'
  };
  const beforeAfter = async (el, propArg, updateDomCb, {
    awaitRaf = true
  } = {}) => {
    const prop = propMap[propArg];
    const before = el[prop];
    await updateDomCb();
    if (awaitRaf) {
      await raf();
    }
    const after = el[prop];
    return {
      before,
      after
    };
  };
  _exports.beforeAfter = beforeAfter;
  const aniMate = async (elements, keyframesArg, optionsArg) => {
    const elementsArray = normalizeElementsArray(elements);
    if (!elementsArray[0]) return Promise.resolve();
    const autoDefaults = {
      rectKeys: true,
      parentGap: 'setsHeight0',
      paddingBlock: 'setsHeight',
      marginBlock: 'setsHeight0',
      marginInline: 'setsWidth0',
      overflowYClip: 'setsHeight',
      overflowXClip: 'setsWidth',
      willChange: '!documentElement'
    };
    let autoOptions = autoDefaults;
    if (typeof optionsArg === 'object') {
      if (optionsArg.auto === false) {
        autoOptions = Object.fromEntries((0, _typeUtils.keys)(autoDefaults).map(k => [k, false]));
      } else if (typeof optionsArg.auto === 'object') {
        autoOptions = Object.assign(autoDefaults, optionsArg.auto);
      }
    }
    const out = await Promise.all(elementsArray.map(async element => {
      const optionsArgObjNormalized = removeUndefinedVals(isInteger(optionsArg) ? {
        duration: optionsArg
      } : !(0, _utils.isNone)(optionsArg) ? optionsArg : {});
      const defaultOptions = {
        setStylesAfter: !optionsArgObjNormalized.pseudoElement,
        easing: 'ease-out-cubic',
        duration: 250,
        auto: autoOptions
      };
      if (typeof keyframesArg === 'string' && keyframePresetNames.includes(keyframesArg)) {
        const preset = keyframePresets[keyframesArg];
        keyframesArg = preset.keyframes;
        Object.assign(defaultOptions, preset.options);
      }
      const options = (0, _deepmerge.default)(defaultOptions, optionsArgObjNormalized);
      if (options.easing && options.easing in easings) {
        // @ts-expect-error https://github.com/microsoft/TypeScript/issues/43284
        options.easing = easings[options.easing];
      }
      const {
        keyframes,
        willChange
      } = processKeyframes({
        element,
        keyframesArg: keyframesArg,
        options
      });
      const firstKeys = (0, _typeUtils.keys)(keyframes[0]);
      const lastKeyframe = keyframes.length > 1 ? keyframes.at(-1) : null;
      const lastKeys = lastKeyframe ? (0, _typeUtils.keys)(lastKeyframe) : null;
      let firstKeysNotInLast = firstKeys;
      if (lastKeys) {
        firstKeysNotInLast = firstKeys.filter(firstKey => !lastKeys.includes(firstKey));
      }
      options.fill = options.fill || (lastKeys && firstKeysNotInLast.length === lastKeys.length ? 'none' : 'both');
      const setWillChange = 'union' in Set.prototype && !(!options.auto.willChange || options.auto.willChange === '!documentElement' && element === document.documentElement);
      const elemDatas = elemsDatas.get(element) || [];
      const elemdata = {
        willChange
      };
      const origWillChange = element.style.willChange;
      const willChangeVarKey = '--aniMateWillChange';
      const willChangeVarVal = `var(${willChangeVarKey})`;
      if (setWillChange && willChange.size > 0) {
        const currentWillChange = elemDatas.reduce((acc, curr) => {
          // @ts-expect-error remove this when set methods are typed in tracked-built-ins package
          return acc.union(curr.willChange);
        }, new Set());
        element.style.setProperty(willChangeVarKey,
        // @ts-expect-error remove this when set methods are typed in tracked-built-ins package
        Array.from(willChange.union(currentWillChange)).join(', '));
        element.style.willChange = willChangeVarVal;
      }
      if (firstKeysNotInLast.length > 0 && lastKeyframe) {
        if (options.auto.rectKeys) {
          rectKeys.forEach(key => {
            if (!(key in lastKeyframe) && key in keyframes[0]) {
              const rect = element.getBoundingClientRect();
              lastKeyframe[key] = pxifyIfNeeded(key, rect[key]);
            }
          });
        }
        if (!('opacity' in lastKeyframe) && 'opacity' in keyframes[0]) {
          lastKeyframe.opacity = parseFloat(keyframes[0].opacity) ? 0 : 1;
        }
      }
      if (options.auto.rectKeys) {
        rectKeys.forEach(key => {
          if (!(key in keyframes[0]) && lastKeyframe?.[key]) {
            const rect = element.getBoundingClientRect();
            keyframes[0][key] = pxifyIfNeeded(key, rect[key]);
          }
        });
      }
      (0, _debug.runInDebug)(() => {
        if (options.debug === 'console') {
          // eslint-disable-next-line no-console
          console.error({
            element,
            keyframes,
            options
          });
        } else if (options.debug) {
          // eslint-disable-next-line no-debugger
          debugger;
        }
      });
      let unsetStyles;
      if (options.style) {
        unsetStyles = setStyles(element, options.style);
      }
      const animation = element.animate(keyframes, options);
      Object.assign(elemdata, {
        animation,
        keyframes,
        firstKeysNotInLast,
        options
      });
      elemDatas.push(elemdata);
      elemsDatas.set(element, elemDatas);
      await animation.finished;
      unsetStyles?.();

      // dont do post animation stuff (reset styles etc.) before all animations are finished
      const allElemAnimationsFinished = !elemDatas.find(({
        animation
      }) => animation.playState !== 'finished');
      if (allElemAnimationsFinished && (options.auto.overflowYClip || options.auto.overflowXClip)) {
        const currentInitial = element.dataset.aniMateInitial ? JSON.parse(element.dataset.aniMateInitial) : {};
        overflowKeys.forEach(key => {
          if (typeof currentInitial[key] === 'string') {
            element.style[key] = currentInitial[key];
          }
        });
        if (typeof currentInitial.overflowClipMargin === 'string') {
          element.style.overflowClipMargin = currentInitial.overflowClipMargin;
        }
        delete element.dataset.aniMateInitial;
      }
      if (['both', 'forwards'].includes(options.fill) && allElemAnimationsFinished /* &&
                                                                                   element.isConnected */) {
        setStylesAfter(element, elemDatas);
      }
      if (setWillChange && willChange.size > 0) {
        element.style.willChange = origWillChange.replace(willChangeVarVal, '');
        element.style.removeProperty(willChangeVarKey);
      }
      elemsDatas.delete(element);
      return animation;
    }));
    return out;
  };
  _exports.aniMate = aniMate;
  aniMate.from = (elements, keyframesArg, optionsArg) => {
    return aniMate(elements, [keyframesArg, {}], optionsArg);
  };
  aniMate.to = (elements, keyframesArg, optionsArg) => {
    return aniMate(elements, [{}, keyframesArg], optionsArg);
  };
  const setStylesAfter = (element, elemDatas) => {
    // this block is because we cant use `commitStyles`.. see: https://github.com/w3c/csswg-drafts/issues/5394
    // With this it wont set styles after animation unless it overrides styles it had before animation
    elemDatas.forEach(({
      keyframes,
      animation,
      options,
      firstKeysNotInLast
    }) => {
      const lastKeyframe = keyframes.at(-1);
      if (lastKeyframe && options.setStylesAfter && !options.pseudoElement) {
        Object.entries(lastKeyframe).forEach(([key, value]) => {
          if (!firstKeysNotInLast.includes(key)) {
            setPropertyOrRegular(element, key, value);
          }
        });
      }
      if (!options.pseudoElement) {
        animation.cancel();
      }
    });
  };
  const afterRenderCb = (timesLeftArg, resolve) => {
    const timesLeft = timesLeftArg - 1;
    if (timesLeft === 0) {
      resolve();
    } else {
      (0, _runloop.scheduleOnce)('afterRender', null, afterRenderCb, timesLeft, resolve);
    }
  };
  function afterRender(times = 1) {
    return new Promise(resolve => {
      (0, _runloop.scheduleOnce)('afterRender', null, afterRenderCb, times, resolve);
    });
  }
  const rafCb = (timesLeftArg, resolve) => {
    const timesLeft = timesLeftArg - 1;
    if (timesLeft === 0) {
      resolve();
    } else {
      requestAnimationFrame(() => rafCb(timesLeft, resolve));
    }
  };
  function raf(times = 1) {
    return new Promise(resolve => {
      requestAnimationFrame(() => rafCb(times, resolve));
    });
  }
  const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
  _exports.wait = wait;
  const removeUndefinedVals = object => {
    return Object.fromEntries(Object.entries(object).filter(([, val]) => typeof val !== 'undefined'));
  };
});