define("ember-ani-mate/AniMateArray", ["exports", "ember-ani-mate"], function (_exports, _emberAniMate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AniMateArray = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const newRow = (object, opt = {}) => ({
    ...opt,
    object
  });
  class AniMateArray extends Array {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "push", this.syncMethodReplacement);
      _defineProperty(this, "pushObject", this.syncMethodReplacement);
      _defineProperty(this, "removeObject", this.syncMethodReplacement);
      _defineProperty(this, "removeAt", this.syncMethodReplacement);
      _defineProperty(this, "insertAt", this.syncMethodReplacement);
      this.forEach((row, index) => {
        this[index] = newRow(row);
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    syncMethodReplacement() {
      throw new Error('use async version of this method instead. Use `{animate: false}` option if you dont want to animate`');
    }
    async asyncRemoveAt(i, {
      animate = true
    } = {}) {
      const row = this[i];
      if (animate) {
        const {
          el
        } = row;
        const betweenEl = row.beforeEl?.isConnected ? row.beforeEl : this[i + 1]?.beforeEl;
        await _emberAniMate.aniMate.to([betweenEl, el], {
          opacity: 0,
          transform: 'scale(0.9)'
        });
        await _emberAniMate.aniMate.to([el, betweenEl], {
          height: 0
        });
      }
      super.removeAt(i);
    }
    objectAt(i) {
      return super.objectAt(i).object;
    }
    asyncInsertAt(i, object, {
      animate = true,
      onInsert
    } = {}) {
      return new Promise(resolve => {
        super.insertAt(i, newRow(object, {
          onInsert: async (row, info) => {
            if (row.el) {
              if (onInsert) {
                await onInsert(row, info);
              } else {
                if (animate) {
                  (0, _emberAniMate.setStyles)([row.beforeEl, row.el], {
                    opacity: 0
                  });
                  await Promise.all([_emberAniMate.aniMate.from(row.beforeEl, {
                    height: 0
                  }), _emberAniMate.aniMate.from(row.el, {
                    height: 0,
                    transform: 'scale(0.9)'
                  })]);
                  (0, _emberAniMate.setStyles)([row.beforeEl, row.el], {
                    opacity: 1
                  });
                  await Promise.all([_emberAniMate.aniMate.from(row.beforeEl, {
                    opacity: 0
                  }), _emberAniMate.aniMate.from(row.el, {
                    opacity: 0,
                    transform: 'scale(0.9)'
                  })]);
                }
              }
            }
            if (info.type === 'el') {
              resolve();
            }
          }
        }));
      });
    }
    asyncPushObject(object, options) {
      return this.asyncInsertAt(this.length, object, options);
    }
    asyncRemoveObject(object) {
      const i = this.findIndex(row => object === row.object);
      return this.asyncRemoveAt(i);
    }
    get onlyObjects() {
      return this.map(({
        object
      }) => object);
    }
  }
  _exports.AniMateArray = AniMateArray;
});