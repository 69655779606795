define("ember-ani-mate/components/smooth-if/glimmer", ["exports", "@ember/component", "teamtailor/config/environment", "@glimmer/component", "@ember/object", "@glimmer/tracking", "ember-ani-mate", "@ember/debug", "teamtailor/utils/arg-default", "teamtailor/utils/justAssert", "@ember/runloop", "@ember/service", "@ember/template-factory"], function (_exports, _component, _environment, _component2, _object, _tracking, _emberAniMate, _debug, _argDefault, _justAssert, _runloop, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{did-update this.didUpdatePredicateArg @predicate}}
  {{did-insert this.setupAwaitInPromise}}
  {{did-insert this.onRegister}}
  
  {{#if (or @hasElseBlock this.predicate)}}
    <div class="flow-root" {{did-insert this.didEnterWrapperDiv}} ...attributes>
      {{yield this.api}}
    </div>
  {{/if}}
  {{this.setIsInitialRender}}
  */
  {
    "id": "ssK8n+Gd",
    "block": "[[[1,[28,[35,0],[[30,0,[\"didUpdatePredicateArg\"]],[30,1]],null]],[1,\"\\n\"],[1,[28,[35,1],[[30,0,[\"setupAwaitInPromise\"]]],null]],[1,\"\\n\"],[1,[28,[35,1],[[30,0,[\"onRegister\"]]],null]],[1,\"\\n\\n\"],[41,[28,[37,3],[[30,2],[30,0,[\"predicate\"]]],null],[[[1,\"  \"],[11,0],[24,0,\"flow-root\"],[17,3],[4,[38,1],[[30,0,[\"didEnterWrapperDiv\"]]],null],[12],[1,\"\\n    \"],[18,4,[[30,0,[\"api\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[30,0,[\"setIsInitialRender\"]]]],[\"@predicate\",\"@hasElseBlock\",\"&attrs\",\"&default\"],false,[\"did-update\",\"did-insert\",\"if\",\"or\",\"yield\"]]",
    "moduleName": "ember-ani-mate/components/smooth-if/glimmer.hbs",
    "isStrictMode": false
  });
  let SmoothIfBaseComponent = _exports.default = (_class = class SmoothIfBaseComponent extends _component2.default {
    setIsInitialRender() {
      if (!this.isInitialRender) return;
      (0, _runloop.scheduleOnce)('afterRender', this, 'setIsInitialRenderFalse');
    }
    setIsInitialRenderFalse() {
      this.isInitialRender = false;
    }
    get predicate() {
      return this._predicate;
    }
    set predicate(val) {
      this._predicate = !!val;
      this._untrackedPredicate = !!val;
    }
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "resizeObserver", _descriptor, this);
      _initializerDefineProperty(this, "devJumpCheck", _descriptor2, this);
      _initializerDefineProperty(this, "delayWhenClipping", _descriptor3, this);
      _defineProperty(this, "isInitialRender", true);
      _defineProperty(this, "_untrackedPredicate", false);
      _initializerDefineProperty(this, "_predicate", _descriptor4, this);
      _initializerDefineProperty(this, "el", _descriptor5, this);
      _initializerDefineProperty(this, "awaitInPromiseResolve", _descriptor6, this);
      _defineProperty(this, "awaitInPromise", void 0);
      _defineProperty(this, "runningAnimations", []);
      if (!!args.predicate !== !!this._untrackedPredicate) {
        this.predicate = args.predicate;
      }
    }
    get axis() {
      return this.args.axis ?? 'y';
    }
    get y() {
      return this.axis === 'y';
    }
    async didEnterWrapperDiv(wrapperEl) {
      this.el = wrapperEl;
      const inAnimation = this.in();
      this.addRunningAnimation(inAnimation);
      await inAnimation;
    }
    get shouldDoDevJumpCheck() {
      return false;
    }
    get onInitialRender() {
      return !!this.args.onInitialRender;
    }
    async in() {
      if (this.isInitialRender && !this.onInitialRender) {
        return;
      }
      if (this.awaitInPromise) {
        let resetStyles;
        if (this.y) {
          resetStyles = (0, _emberAniMate.setStyles)(this.el, {
            overflow: 'clip',
            height: 0
          });
        }
        await this.awaitInPromise;
        this.setupAwaitInPromise();
        resetStyles?.();
      }
      let parent, parentRectBefore;
      (0, _debug.runInDebug)(() => {
        if (this.shouldDoDevJumpCheck) {
          parent = this.el?.parentElement;
          parentRectBefore = parent.getBoundingClientRect();
        }
      });
      const widthOrHeight = this.y ? 'height' : 'width';
      await Promise.all([_emberAniMate.aniMate.from(this.el, {
        [widthOrHeight]: 0
      }, {
        debug: this.args.debug
      }), _emberAniMate.aniMate.from(this.el, {
        opacity: 0,
        scale: '0.99'
      }, {
        duration: 150,
        ...(this.delayWhenClipping && {
          delay: 150,
          easing: 'ease-in-cubic'
        }),
        debug: this.args.debug
      })]);
      if (this.shouldDoDevJumpCheck) {
        await this.checkParentRectBeforeAfter(this.el, () => {
          (0, _justAssert.justAssert)(this.el);
          const rectAfter = parent.getBoundingClientRect();
          if (Math.round(parentRectBefore.height) !== Math.round(rectAfter.height)) {
            (0, _emberAniMate.setStyles)([this.el.previousElementSibling, this.el, this.el.nextElementSibling], {
              outline: '4px solid red',
              outlineOffset: '-2px'
            });
            this.error(JUMP_DETECTED_ERROR_MESSAGE);
          }
        });
      }
    }
    setupAwaitInPromise() {
      if (this.args.awaitIn) {
        this.awaitInPromise = new Promise(resolve => {
          this.awaitInPromiseResolve = () => {
            resolve();
            this.setupAwaitInPromise();
          };
        });
      }
    }
    get api() {
      const parentThis = this;
      return {
        get predicate() {
          return parentThis.predicate;
        },
        get inDone() {
          return parentThis.awaitInPromiseResolve;
        }
      };
    }
    async addRunningAnimation(promise) {
      this.runningAnimations.push(promise);
      await promise;
      this.runningAnimations = this.runningAnimations.filter(p => p !== promise);
    }
    async out() {
      (0, _justAssert.justAssert)(this.el);
      await Promise.all([_emberAniMate.aniMate.to(this.el, {
        opacity: 0,
        scale: '0.99'
      }, {
        duration: 150,
        debug: this.args.debug,
        setStylesAfter: false
      }), _emberAniMate.aniMate.to(this.el, this.y ? {
        height: 0
      } : {
        width: 0
      }, {
        ...(this.delayWhenClipping && {
          delay: 100
        }),
        debug: this.args.debug,
        setStylesAfter: false
      })]);
      if (this.shouldDoDevJumpCheck) {
        await this.checkParentRectBeforeAfter(this.el, () => {
          (0, _justAssert.justAssert)(this.el);
          const parent = this.el.parentElement;
          const rectBefore = parent?.getBoundingClientRect();
          const commentEl = document.createComment('');
          this.el.replaceWith(commentEl);
          const rectAfter = parent?.getBoundingClientRect();
          commentEl.replaceWith(this.el);
          if (Math.round(rectBefore.height) !== Math.round(rectAfter.height)) {
            (0, _emberAniMate.setStyles)([this.el.previousElementSibling, this.el, this.el.nextElementSibling], {
              outline: '2px solid red'
            });
            this.error(JUMP_DETECTED_ERROR_MESSAGE);
          }
        });
      }
    }
    async checkParentRectBeforeAfter(el, cb) {
      (0, _justAssert.justAssert)(el);
      if (_environment.default.environment === 'development' && this.devJumpCheck) {
        await new Promise(resolve => {
          (0, _debug.runInDebug)(async () => {
            if (this.runningAnimations.length > 1 || !this.el?.isConnected) {
              resolve();
              return;
            }
            await (0, _emberAniMate.raf)(); // to avoid jankyness in dev

            await cb(el);
            resolve();
          });
        });
      }
    }
    async didUpdatePredicateArg([predicateArg]) {
      if (this.runningAnimations.length) {
        await Promise.all(this.runningAnimations);
        await (0, _emberAniMate.afterRender)();
      }
      const _untrackedPredicateBeforeChanged = this._untrackedPredicate;
      if (!!predicateArg !== !!this._untrackedPredicate) {
        if (predicateArg) {
          this.predicate = predicateArg;
        } else {
          if (!this.args.hasElseBlock) {
            await this.addRunningAnimation(this.out());
          }
          this.predicate = predicateArg;
        }
      }
      if (this.el?.isConnected && predicateArg !== _untrackedPredicateBeforeChanged) {
        const before = this.el.getBoundingClientRect();
        (0, _runloop.scheduleOnce)('afterRender', this, this.predicateChangedAfterRender, predicateArg, before);
      }
    }
    async predicateChangedAfterRender(predicateArg, before) {
      (0, _justAssert.justAssert)(this.el);
      if (!this.el.isConnected) {
        return;
      }
      if (!predicateArg && this.args.awaitIn === 'else' || predicateArg && this.args.awaitIn === true) {
        await this.awaitInPromise;
      }
      const after = this.el.getBoundingClientRect();
      const currentAnimations = this.el.getAnimations();
      if (!currentAnimations.length) {
        const heightChanged = Math.round(before.height) !== Math.round(after.height);
        const widthChanged = Math.round(before.width) !== Math.round(after.width);
        await Promise.all([_emberAniMate.aniMate.from(this.el, {
          ...(heightChanged && {
            height: before.height
          }),
          ...(widthChanged && {
            width: before.width
          })
        }, {
          style: {
            overflow: 'hidden'
          },
          setStylesAfter: false,
          debug: this.args.debug,
          duration: 150
        }), _emberAniMate.aniMate.from(this.el, {
          scale: '0.99'
        }, {
          duration: 200,
          setStylesAfter: false,
          debug: this.args.debug
        })]);
        if (this.shouldDoDevJumpCheck) {
          await this.checkParentRectBeforeAfter(this.el, () => {
            (0, _justAssert.justAssert)(this.el);
            const rectAfter = this.el.getBoundingClientRect();
            if (Math.round(after.height) !== Math.round(rectAfter.height)) {
              (0, _emberAniMate.setStyles)([this.el.previousElementSibling, this.el, this.el.nextElementSibling], {
                outline: '4px solid red',
                outlineOffset: '-2px'
              });
              this.error(JUMP_DETECTED_ERROR_MESSAGE);
            }
          });
        }
      }
    }
    onRegister() {
      this.args.onRegister?.(this.api);
    }
    error(textArg) {
      const text = `${textArg}\n\n(This is only shown in dev environment)`;
      if (this.el) {
        const el = document.createElement('div');
        el.getAnimations().forEach(a => a.cancel());
        el.removeAttribute('style');
        el.style.backgroundColor = 'red';
        el.style.color = 'white';
        el.style.marginBlock = '16px';
        el.style.padding = '8px';
        el.innerHTML = text.replaceAll('\n', '<br>');
        this.el.after(el);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "resizeObserver", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "devJumpCheck", [_argDefault.argDefault], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "delayWhenClipping", [_argDefault.argDefault], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setIsInitialRender", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setIsInitialRender"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setIsInitialRenderFalse", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setIsInitialRenderFalse"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_predicate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "el", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didEnterWrapperDiv", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didEnterWrapperDiv"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "in", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "in"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "awaitInPromiseResolve", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupAwaitInPromise", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupAwaitInPromise"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addRunningAnimation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addRunningAnimation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "out", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "out"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didUpdatePredicateArg", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didUpdatePredicateArg"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "predicateChangedAfterRender", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "predicateChangedAfterRender"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRegister", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onRegister"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "error", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype), _class);
  const JUMP_DETECTED_ERROR_MESSAGE = `jump detected. Possibly because of margins on this or surrounding elements and/or collapsing margins. Elements that could be or contain the cause are outlined.

Things to check/try on them and their children:

        1. remove margins
        2. use flex (not grid) gap on parent instead of margins on this and surrounding elements
        3. there is an element that resizes shortly after being shown (like redactor wysiwyg, pdf viewer, etc.). Maybe use the \`awaitIn=true\` argument to wait for resizing being done
        4. if none of the above works you can disable this check by setting {{#smooth-if devJumpCheck=false}}`;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SmoothIfBaseComponent);
});