define("ember-ani-mate/animate.css/bounce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bounceNoScale = _exports.bounceBase = _exports.bounce = void 0;
  const bounceBase = ({
    scale = true
  } = {}) => ({
    keyframes: [{
      offset: 0,
      easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      transform: 'translate3d(0, 0, 0)'
    }, {
      offset: 0.2,
      easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      transform: 'translate3d(0, 0, 0)'
    }, {
      offset: 0.4,
      easing: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
      transform: `translate3d(0, -30px, 0) ${scale ? 'scaleY(1.1)' : ''}`
    }, {
      offset: 0.43,
      easing: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
      transform: `translate3d(0, -30px, 0) ${scale ? 'scaleY(1.1)' : ''}`
    }, {
      offset: 0.53,
      easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      transform: 'translate3d(0, 0, 0)'
    }, {
      offset: 0.7,
      easing: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
      transform: `translate3d(0, -15px, 0) ${scale ? 'scaleY(1.05)' : ''}`
    }, {
      offset: 0.8,
      'transition-timing-function': 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      transform: `translate3d(0, 0, 0) ${scale ? 'scaleY(0.95)' : ''}`
    }, {
      offset: 0.9,
      transform: `translate3d(0, -4px, 0) ${scale ? 'scaleY(1.02)' : ''}`
    }, {
      offset: 1,
      easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      transform: 'translate3d(0, 0, 0)'
    }],
    options: {
      duration: 1000
    }
  });
  _exports.bounceBase = bounceBase;
  const bounce = _exports.bounce = bounceBase();
  const bounceNoScale = _exports.bounceNoScale = bounceBase({
    scale: false
  });
});