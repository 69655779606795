define("ember-ani-mate/components/ani-mate-list-child", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes {{did-insert @onInsert}} {{did-update @onUpdate @index}}>
    {{yield}}
  </div>
  */
  {
    "id": "sAx0fWZp",
    "block": "[[[11,0],[17,1],[4,[38,0],[[30,2]],null],[4,[38,1],[[30,3],[30,4]],null],[12],[1,\"\\n  \"],[18,5,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@onInsert\",\"@onUpdate\",\"@index\",\"&default\"],false,[\"did-insert\",\"did-update\",\"yield\"]]",
    "moduleName": "ember-ani-mate/components/ani-mate-list-child.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});