define("ember-infinity/templates/components/infinity-loader", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isVisible}}
    <div
      {{did-insert this.didInsertLoader this}}
      class="{{this.loaderClassNames}}{{if this.viewportEntered " in-viewport"}}{{if this.isDoneLoading " reached-infinity"}}"
      data-test-infinity-loader
    >
      {{#if (has-block)}}
        {{yield this.infinityModelContent}}
      {{else}}
        {{#if this.isDoneLoading}}
          <span>{{this.loadedText}}</span>
        {{else}}
          <span>{{this.loadingText}}</span>
        {{/if}}
      {{/if}}
    </div>
  {{/if}}
  
  */
  {
    "id": "jC7g+4pU",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[1,\"  \"],[11,0],[16,0,[29,[[30,0,[\"loaderClassNames\"]],[52,[30,0,[\"viewportEntered\"]],\" in-viewport\"],[52,[30,0,[\"isDoneLoading\"]],\" reached-infinity\"]]]],[24,\"data-test-infinity-loader\",\"\"],[4,[38,1],[[30,0,[\"didInsertLoader\"]],[30,0]],null],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"      \"],[18,1,[[30,0,[\"infinityModelContent\"]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isDoneLoading\"]],[[[1,\"        \"],[10,1],[12],[1,[30,0,[\"loadedText\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[12],[1,[30,0,[\"loadingText\"]]],[13],[1,\"\\n\"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"did-insert\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-infinity/templates/components/infinity-loader.hbs",
    "isStrictMode": false
  });
});