define("ember-ani-mate/animate.css/defaults", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaults = void 0;
  const defaults = _exports.defaults = {
    duration: 1000
  };
});