define("ember-ani-mate/components/ani-mate-list", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "ember-ani-mate", "@ember/debug", "@ember/template-factory"], function (_exports, _component, _component2, _object, _emberAniMate, _debug, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.array as |row index|}}
    {{#if (and (has-block "between") (gt index 0))}}
      {{yield
        (component
          "ani-mate-list-child"
          index=index
          onInsert=(fn this.onInsertChild "beforeEl" index)
          onUpdate=(fn this.onUpdateChild "beforeEl" index)
        )
        row.object
        index
        this.array
        to="between"
      }}
    {{/if}}
    {{#if (has-block "item")}}
      {{yield
        (component
          "ani-mate-list-child"
          index=index
          onInsert=(fn this.onInsertChild "el" index)
          onUpdate=(fn this.onUpdateChild "el" index)
        )
        row.object
        index
        this.array
        to="item"
      }}
    {{else}}
      {{yield
        (component
          "ani-mate-list-child"
          index=index
          onInsert=(fn this.onInsertChild "el" index)
          onUpdate=(fn this.onUpdateChild "el" index)
        )
        row.object
        index
        this.array
      }}
    {{/if}}
  {{/each}}
  */
  {
    "id": "1D8hXUuf",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"array\"]]],null]],null],null,[[[41,[28,[37,3],[[48,[30,3]],[28,[37,5],[[30,2],0],null]],null],[[[1,\"    \"],[18,3,[[50,\"ani-mate-list-child\",0,null,[[\"index\",\"onInsert\",\"onUpdate\"],[[30,2],[28,[37,8],[[30,0,[\"onInsertChild\"]],\"beforeEl\",[30,2]],null],[28,[37,8],[[30,0,[\"onUpdateChild\"]],\"beforeEl\",[30,2]],null]]]],[30,1,[\"object\"]],[30,2],[30,0,[\"array\"]]]],[1,\"\\n\"]],[]],null],[41,[48,[30,4]],[[[1,\"    \"],[18,4,[[50,\"ani-mate-list-child\",0,null,[[\"index\",\"onInsert\",\"onUpdate\"],[[30,2],[28,[37,8],[[30,0,[\"onInsertChild\"]],\"el\",[30,2]],null],[28,[37,8],[[30,0,[\"onUpdateChild\"]],\"el\",[30,2]],null]]]],[30,1,[\"object\"]],[30,2],[30,0,[\"array\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,5,[[50,\"ani-mate-list-child\",0,null,[[\"index\",\"onInsert\",\"onUpdate\"],[[30,2],[28,[37,8],[[30,0,[\"onInsertChild\"]],\"el\",[30,2]],null],[28,[37,8],[[30,0,[\"onUpdateChild\"]],\"el\",[30,2]],null]]]],[30,1,[\"object\"]],[30,2],[30,0,[\"array\"]]]],[1,\"\\n\"]],[]]]],[1,2]],null]],[\"row\",\"index\",\"&between\",\"&item\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"and\",\"has-block\",\"gt\",\"yield\",\"component\",\"fn\"]]",
    "moduleName": "ember-ani-mate/components/ani-mate-list.hbs",
    "isStrictMode": false
  });
  let AniMateListComponent = _exports.default = (_class = class AniMateListComponent extends _component2.default {
    constructor() {
      super(...arguments);
      (false && !(this.args.array instanceof _emberAniMate.AniMateArray) && (0, _debug.assert)('You have to provide an AniMateArray', this.args.array instanceof _emberAniMate.AniMateArray));
    }
    get array() {
      return this.args.array;
    }
    onInsertChild(type, index, element) {
      this.array[index] = this.array[index] || {};
      this.array[index][type] = element;
      this.array[index].onInsert?.call?.(this.array, this.array[index], {
        type,
        index
      });
    }
    onUpdateChild(type, index, element) {
      if (this.array[index]) {
        this.array[index][type] = element;
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "onInsertChild", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsertChild"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdateChild", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateChild"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AniMateListComponent);
});